<template>
  <div>
    <h2 v-html="$t('subsidy_processing_title')"></h2>
    <!-- Abschlussmonat von -->
    <DbmMonthPicker
      id="ProcessingForm_validFrom"
      v-model="searchCardStore.ProcessingForm_validFrom"
      :dateLabel="$t('subsidy_processing_valid_from')"
      :clearable="true"
    />
    <!-- bis -->
    <DbmMonthPicker
      id="ProcessingForm_validUntil"
      v-model="searchCardStore.ProcessingForm_validUntil"
      :dateLabel="$t('subsidy_processing_valid_until')"
      :clearable="true"
      returnType="lastOfMonth"
    />
    <!-- Korrektur -->
    <v-select
      id="subsidy_processing_version"
      v-model="searchCardStore.ProcessingForm_correction"
      :label="$t('subsidy_processing_version')"
      :placeholder="$t('subsidy_processing_version_all')"
      :items="correction"
      clearable
      @keyup.enter="search"
    />
    <!-- Gesuchs-ID -->
    <v-text-field
      id="subsidy_processing_ident"
      v-model="searchCardStore.ProcessingForm_ident"
      :label="$t('subsidy_processing_ident')"
      type="number"
      @keyup.enter="search"
    />
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import DbmMonthPicker from '@/components/dbmMonthPicker.vue'
import { useSearchProcessingFormStore } from '@/store/SearchProcessingFormStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchSubsidyProcessing',
  extends: baseSearchVue,
  data() {
    return {
      correction: [
        { text: this.$t('subsidy_processing_version_yes'), value: 1 },
        { text: this.$t('subsidy_processing_version_no'), value: 0 }
      ]
    }
  },
  components: {
    DbmMonthPicker: DbmMonthPicker
  },
  beforeMount() {
    this.setSearchCardStore(useSearchProcessingFormStore(), true)
  }
})
</script>
